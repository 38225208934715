import React, { useState } from 'react';

/**
 * Header renders the top navigation
 * Uses tailwind css classes
 */
const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <header className="bg-gray-900 text-white p-5 flex justify-between items-center">
            <div className="logo">
                {/* Logo goes here, e.g., an image or text */}
                <a href="/" className="text-xl font-bold">AT</a>
            </div>
            <nav className={`lg:flex ${isOpen ? "block" : "hidden"} lg:items-center lg:space-x-6`}>
                <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                    <li className="nav-item">
                        <a href="/" className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-75">Home</a>
                    </li>
                    <li className="nav-item">
                        <a href="/photography" className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-75">Photography</a>
                    </li>
                    <li className="nav-item">
                        <a href="/projects" className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug hover:opacity-75">Projects</a>
                    </li>
                </ul>
            </nav>
            <button className="lg:hidden" onClick={() => setIsOpen(!isOpen)}>
                {/* Hamburger Icon */}
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path></svg>
            </button>
        </header>
    );
};

export default Header;
import React from 'react';
import { Link } from 'react-router-dom';
import Header from './components/header.js';

const HomePage = () => {
  return (
    <div>
      <Header />
      <div className="text-center flex flex-col justify-center items-center h-[70vh]">
        <h1 className="text-white text-6xl font-bold drop-shadow-md mb-5">Anthony Thorogood</h1>
        <p className="text-3xl text-gray-400 ">
          Software Engineer | Problem Solver | <span className="text-blue-500 underline"><Link to="/photography">Photographer</Link></span>
          
        
      
        </p>
        <p className="text-3xl font-bold m-6">
          <a href="https://github.com/pulsebox/" className="underline underline-offset-1" >GitHub</a> | <a href="https://www.linkedin.com/in/anthony-thorogood-2657ba2b/" className="underline underline-offset-1">LinkedIn</a>
        </p>
        <a href="/projects" className="flex justify-center mt-8">
          <button class="bg-gradient-to-r from-blue-500 to-blue-300 text-white py-4 px-8 border-none rounded font-bold text-lg uppercase cursor-pointer transition-all duration-800 ease-in-out hover:bg-blue-700 hover:scale-110 hover:shadow-lg">Chat With 'Me'</button>
        </a>
      </div>
    </div>
  );
};

export default HomePage;
import React from 'react';
import photo1 from './images/DSC07320.jpg';
import photo2 from './images/DSC06232.jpg';
import photo3 from './images/Tesla.jpg';
import photo4 from './images/DSC06091.jpg';
import Header from './components/header';

const PhotoCaption = ({ children }) => (
  <p className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-70 text-white py-2 text-sm text-center hover:bg-sky-700">
    {children}
  </p>
);

const PhotoItem = ({ src, alt }) => (
  <div className="relative overflow-hidden rounded shadow-lg transition-transform transform hover:scale-105">
    <img src={src} alt={alt} />
    <PhotoCaption children={alt} />
  </div>
);

const Photography = () => {
  return (
    <div >
      <Header />
      <div className='px-3'>
        <h2 className="text-4xl mb-4 drop-shadow-lg text-white ">My Photography</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <PhotoItem src={photo1} alt="Dusk in New York" />
          <PhotoItem src={photo2} alt="Durdle Door has visitors" />
          <PhotoItem src={photo3} alt="Tesla in New York" />

          {/* Add more photo items */}
        </div>
        <br />
        <div className="grid">
          <PhotoItem src={photo4} alt="English Beach with grand white cliffs" />

        </div>
      </div>
    </div>
  );
};

export default Photography;
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './homepage.js';
import Photography from './photography.js';
import Projects from './projects.js';
// import ProjectsPage from './projectpage.js';

const ExternalRedirect = () => {
  useEffect(() => {
    window.location.href = 'https://hyperdesk-ai.vercel.app/';
  }, []);

  return null; // Component does not render anything
};

function App() {
  return (
    <Router>
      <div className="bg-stone-800 text-white min-h-screen">
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="/photography" element={<Photography />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/hyperdesk" element={<ExternalRedirect />} /> {/* Redirect to external link for now */}
          <Route path="*" element={<h1>404 Not Found</h1>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

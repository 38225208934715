import React from 'react';
import Header from './components/header';

const Projects = () => {
    return (
        <>
            <Header />
            <div id='projects' className='block p-20 max-w-[75vh]'>
                <div id='decListOfProjects' className='grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1'>
                    <div className='border-stone-400 bg-zinc-800 rounded-xl min-h-20 min-w-36'>
                        <span className="p-8 inline font-sans text-7xl font-bold tracking-tighter leading-[64px] break-words text-left antialiased bg-clip-text bg-gradient-to-r from-[rgb(189,52,254)] from-20%  via-transparent via-[49%] to-[rgb(60,86,235)] to-70%  text-transparent">
                            HyperDeskAI
                        </span>
                        <p className='p-8 text-zinc-200'>HyperDeskAI is planning to be a platform that helps businesses to automate/10x their customer service and support operations. It is more than a chatbot, it will be an agent that can be integrated with various platforms like WhatsApp, Facebook Messenger, and websites. It can handle customer queries, provide technical information, and even create new documentation.</p>
                        <button className="ml-8 mb-8 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Learn More
                        </button>
                    </div>
                    <div className='border-stone-400 bg-zinc-800 rounded-xl min-h-20 min-w-36'>
                        <span className="p-8 text-zinc-100 inline font-sans text-7xl font-bold tracking-tighter leading-[64px] break-words text-left antialiased ">
                            ChatWithMe
                        </span>
                        <p className='p-8 text-zinc-200'>AI Chatbot that is infused with knowledge from my personal experiences and learnings. A little tech demo for RAG, it can teach you more about me and my work.</p>
                        <a href='https://hyperdesk-ai.vercel.app/' target='_blank' rel='noreferrer'>
                        <button className="ml-8 mb-8 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Try the demo!
                        </button>
                        </a>
                    </div>
                </div>

            </div>
            

        </>
    );
};

export default Projects;
